* {
  font-family: "Space Mono";
}

.content {
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: space-between;

  background-color: #141414;
}

.foto-1 {
  height: 100vh;
  width: 35%;
}

.formulario {
  height: 100vh;
  width: 65%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.centro {
  max-width: 450px;
  padding: 3rem;

  backdrop-filter: blur(1.5px);

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.title h1 {
  font-size: 1.8rem;
}

.title {
  color: #fff;
}

.form-control {
  background-color: transparent;
  color: #fff;
  border-radius: 0px;
  border: 0px;
  outline: none;
  padding: 10px 15px;
  color: #fff !important;
}

.form-control:focus {
  background-color: transparent;
  border-radius: 0px;
  border: 0px;
  outline: none !important;
  color: #fff !important;
  box-shadow: none;
}

.form-control::placeholder {
  opacity: 0.5;
  color: #fff !important;
}

.mensagem {
  display: flex;
  flex-direction: column;
}

.border-green {
  border: 2px solid #dff263 !important;
}

.border-red {
  border: 2px solid #ff7b61 !important;
}

.btn.btn-green {
  margin: 10px;
  margin-top: 30px;
  background-color: #dff263;
  color: #141414;
  border-radius: 0px;
}

video {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  border-radius: 0px;
  border-right: 3px solid #dff263;
}

@media (max-width: 768px) {
  .foto-1 {
    display: none;
  }

  .formulario{
    width: 100%;
  }
}
